import React, { useState, Suspense, lazy, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './layouts/authorization/login/LoginPage';
import Header from './components/header/Header';
import DynamicComponent from './components/dynamicHeader/DynamicComponent';
import { getAuthToken, getRequest } from './services/fetch/AxiosHelper';
import HomePage from './layouts/homepage/HomePage';
import Footer from './components/footer/Footer';
import CategoryPage from './layouts/category/CategoryPage';
import PricelistPage from './layouts/pricelist/PricelistPage';
import CartPage from './layouts/cart/CartPage';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import JobsPage from './layouts/jobs/JobsPage.js';
import { isUserAdmin } from './services/cookie/CookieManager.js';
import { ClipLoader } from 'react-spinners';
import AdminDynamicComponent from './components/dynamicHeader/AdminDynamicComponent';

const AdminRoutes = lazy(() => import('./layouts/common/AdminRoutes.js'))
const AdminHeader = lazy(() => import('./layouts/admin/header/AdminHeader'));

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const ProtectedRoute = ({ children }) => {
    
    const isAuthenticated = () => {
      return isUserAdmin(); 
    };
  
    useEffect(() => {
      if (!isAuthenticated()) {
        //checkUserInfo()
      }
    }, []);
  
    //return isAuthenticated() ? children : null; 
    return children;
     
  };

  const checkUserInfo = () => {
    getRequest("/user/allowed")
              .then((response) => response.data)
              .then((data) => {
                if (data.role !== 'ADMIN') {
                  navigate('/404'); 
                }
              })
              .catch((error) => {
                  console.log(error)
                  navigate('/404'); 
              });
  }

  return (
    <div>
      <Provider store={store}>
      <DynamicComponent>
        <Header />
      </DynamicComponent>
      <AdminDynamicComponent>
        <AdminHeader/>
      </AdminDynamicComponent>
      <div className="page-container">
        <Suspense fallback={
          <div className={"loading"} > 
            <ClipLoader color="#FE754B" size={250}/> 
          </div>
        }>
          <Routes>
            <Route path="/"  element={<HomePage />} />
            <Route path="/category" element={<CategoryPage/>} />
            <Route path="/pricelist"  element={<PricelistPage />} />
            <Route path="/cart"  element={<CartPage />} />
            <Route path="/jobs"  element={<JobsPage />} />

            <Route 
              path="admin/*" 
              element={
                <ProtectedRoute>
                  <AdminRoutes />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </Suspense>
      </div>
      <DynamicComponent>
        <Footer/>
      </DynamicComponent>
      </Provider>
    </div>
  );
};

export default App;