import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from "js-cookie";

const AdminDynamicComponent = ({ children }) => {
  const location = useLocation();
  const [showNavBar, setShowNavBar] = useState(false);

  useEffect(() => {
    const isAdminPanel = new RegExp(`^\/admin`).test(location.pathname) && !Cookies.get("is_signal");

    setShowNavBar(isAdminPanel);
  }, [location]);

  return (
    <div>
      {showNavBar && children}
    </div>
  );
};

export default AdminDynamicComponent;
